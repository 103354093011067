<template>
  <div class="home" :style="{ backgroundImage: `url(${backgroundImage})` }">
    <div class="board">
      <CardItem :cardInfo="items[0]"></CardItem>
      <CardItem :cardInfo="items[1]"></CardItem>
      <CardItem :cardInfo="items[2]"></CardItem>
    </div>
  </div>
</template>

<script>
import CardItem from "@/components/CardItem";
export default {
  name: "HomeView",
  components: {
    CardItem,
  },
  data() {
    return {
      bgUrl: require("@/assets/homebg.png"),
      // items: [
      //   {
      //     title: this.$t("home.feedback"),
      //     desc: "描述问题，获取帮助",
      //     icon: require("@/assets/icon/feedback.png"),
      //     path: "/feedback",
      //   },
      //   {
      //     title: "技术支持引擎",
      //     desc: "了解产品，技术需求",
      //     icon: require("@/assets/icon/techsupport.png"),
      //     path: "/techsupport",
      //   },
      //   {
      //     title: "产品开发需求",
      //     desc: "客户产品需求",
      //     icon: require("@/assets/icon/product.png"),
      //     path: "/demand",
      //   },
      // ],
    };
  },
  computed: {
    backgroundImage() {
      const lang = this.$i18n.locale;
      switch (lang) {
        case "zh":
          return require("@/assets/homebg.png");
        case "en":
          return require("@/assets/homebg_en.png");
        default:
          return require("@/assets/homebg.png");
      }
    },
    items() {
      return [
        {
          title: this.$t("home.feedback"),
          desc: this.$t("home.feedbackSubTitle"),
          icon: require("@/assets/icon/feedback.png"),
          path: "/feedback",
        },
        {
          title: this.$t("home.techSupport"),
          desc: this.$t("home.techSupportSubTitle"),
          icon: require("@/assets/icon/techsupport.png"),
          path: "/techsupport",
        },
        {
          title: this.$t("home.demand"),
          desc: this.$t("home.demandSubTitle"),
          icon: require("@/assets/icon/product.png"),
          path: "/demand",
        },
      ];
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100vh;
  // background: url("@/assets/homebg.png") no-repeat center center / cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .board {
    width: 100%;
    margin-top: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
