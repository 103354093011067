<template>
  <div id="app" class="app">
    <div class="container" :style="{ width: computedWidth }">
      <div style="position: absolute; top: 30px; right: 30px">
        <LangSelect></LangSelect>
      </div>
      <router-view />
    </div>
    <!-- <el-row :gutter="10">
      <el-col
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 12, offset: 6 }"
        :md="{ span: 12, offset: 6 }"
        :lg="{ span: 7, offset: 8 }"
        :xl="{ span: 6, offset: 9 }"
      >
        <router-view />
      </el-col>
    </el-row> -->
  </div>
</template>

<script>
import LangSelect from "@/components/LangSelect";
export default {
  components: {
    LangSelect,
  },
  data() {
    return {
      uiWidth: 0,
    };
  },
  computed: {
    computedWidth() {
      return `${this.uiWidth}px`;
    },
  },
  created() {},
  mounted() {
    this.initViewProperty();
    window.addEventListener("resize", this.initViewProperty);
  },
  methods: {
    initViewProperty() {
      const element = document.getElementsByClassName("app")[0];
      const windowWidth = element.offsetWidth;
      this.uiWidth = windowWidth > 435 ? 430 : windowWidth;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.container {
  position: relative;
}
</style>
