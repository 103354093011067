export default {
  queryTip: "Query",
  resetTip: "Reset",
  exportTip: "Export",
  confirmTip: "Confirm",
  cancelTip: "Cancel",
  addBtn: "Add",
  editBtn: "Edit",
  delBtn: "Delete",
  back: "Back",
  companyName: "Company Name",
  phCompanyName: "Please input company name",
  name: "Your Name",
  phName: "Please input your name",
  phone: "Phone",
  phPhone: "Please input contact person phone number",
  email: "Email",
  phEmail: "Please input contact person email address",
  product: "Product",
  phProduct: "Please input product full name or designation",
  desc: "Problem description",
  phDesc:
    "Please describe the problem in detail to help us better understand the problem.",
  uploadImg: "Upload image",
  yzm: "Verification code",
  phYzm: "Please input verification code",
  submitBtn: "Submit",
  submitSuccess: "Submission successful",
  feedbackSuccess:
    "Issue feedback successful; please await contact from the relevant personnel.",
  submitData: "Submitting data...",
  productDevReq: "Product development requirement",
  phProductDevReq: "Please describe you requirement for product development",
  uploadAttach: "Upload attachment",
  demandSuccess:
    "Product development requirement submitted successfully; please await contact from the relevant personnel.",

  home: {
    feedback: "Quality Issue Feedback",
    feedbackSubTitle: "Describe Issues, Get Assistance",
    techSupport: "Product and Process Information",
    techSupportSubTitle:
      "Get to know the information about our products and processes",
    demand: "Product Development Requirements",
    demandSubTitle: "Customer Product Requirement Description",
  },
  techSupport: {
    productNameDesc: "Product you want to know about",
    inputPlaceHolder:
      "Please enter the name of the product or process you need to know about or designation",
    tip: "Notes 🔔: Fill in the full product name or process name as much as possible, e.g. Product: E6CR-1200-386, Process: Glass fiber products for pipe forming.",
    btnManual: "Product Inquiry",
    btnManualSubTitle:
      "Corresponding product performance and usage instructions",
    btnTechnique: "Process Inquiry",
    btnTechniqueSubTitle:
      "Corresponding product usage and process instructions",
    warningTip: "Product name not filled in",
    noManual:
      "There is no instruction manual for this product or the query is not supported.",
    noTechnique:
      "There is no description of the process or the query is not supported.",
    networkError: "Please check the current network",
    prefix: "Got ",
    manualSuccess: " items related product info",
    techniqueSuccess: " items related process info",
  },
  dovView: {
    feedback: "Quality Issue",
    demand: "Development Requires",
  },
};
