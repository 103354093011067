import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/HomeView.vue";
import AboutView from "@/views/AboutView.vue";
import FeedbackView from "@/views/quality/Feedback.vue";
import TechSupportView from "@/views/quality/TechSupport.vue";
import DemandView from "@/views/quality/Demand.vue";
import DocView from "@/views/quality/DocView.vue";
// import { component } from "vue/types/umd";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
  {
    path: "/feedback",
    name: "Feedback",
    component: FeedbackView,
  },
  {
    path: "/techsupport",
    name: "TechSupport",
    component: TechSupportView,
  },
  {
    path: "/demand",
    name: "demand",
    component: DemandView,
  },
  {
    path: "/docView",
    name: "文件预览",
    component: DocView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
