<template>
  <div class="feedback-form" :style="{ backgroundImage: `url(${demandBg})` }">
    <div class="container-fb">
      <div class="top-box" @click="goBack">
        <i
          style="font-size: 16px; font-weight: bold"
          class="el-icon-arrow-left"
        ></i>
        <span class="navigator">{{ $t("back") }}</span>
      </div>
      <div class="center-box">
        <el-form ref="form" :model="form" :rules="rules" style="width: 100%">
          <el-form-item prop="companyName">
            <span slot="label" class="form-label">{{ $t("companyName") }}</span>
            <el-input
              v-model="form.companyName"
              class="form-input"
              :placeholder="$t('phCompanyName')"
            />
          </el-form-item>
          <el-form-item prop="contactPerson">
            <span slot="label" class="form-label">{{ $t("name") }}</span>
            <el-input
              v-model="form.contactPerson"
              class="form-input"
              :placeholder="$t('phName')"
            />
          </el-form-item>
          <el-form-item prop="phone">
            <span slot="label" class="form-label">{{ $t("phone") }}</span>
            <el-input
              v-model="form.phone"
              class="form-input"
              :placeholder="$t('phPhone')"
            />
          </el-form-item>
          <el-form-item prop="email">
            <span slot="label" class="form-label">{{ $t("email") }}</span>
            <el-input
              v-model="form.email"
              class="form-input"
              :placeholder="$t('phEmail')"
            />
          </el-form-item>
          <el-form-item prop="productName">
            <span slot="label" class="form-label">{{ $t("product") }}</span>
            <el-input
              v-model="form.productName"
              class="form-input"
              :placeholder="$t('phProduct')"
            />
          </el-form-item>
          <el-form-item prop="desc">
            <span slot="label" class="form-label">{{
              $t("productDevReq")
            }}</span>
            <el-input
              type="textarea"
              v-model="form.desc"
              class="form-input"
              :placeholder="$t('phProductDevReq')"
              :rows="6"
              maxlength="800"
              show-word-limit
            />
          </el-form-item>
          <el-form-item>
            <span slot="label" class="form-label">{{
              $t("uploadAttach")
            }}</span>
          </el-form-item>
          <el-form-item>
            <el-upload
              ref="upload"
              :limit="5"
              accept=".jpg, .jpeg, .pdf, .png, .gif, .doc, .docx, .zip, .rar"
              :headers="upload.headers"
              :action="upload.url + '?updateSupport=' + upload.updateSupport"
              list-type="picture-card"
              style="text-align: left"
              :disabled="upload.isUploading"
              :http-request="handleUploadFile"
              :on-progress="handleFileUploadProgress"
              :on-success="handleFileSuccess"
              :auto-upload="true"
            >
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    v-if="updateIf(file)"
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <!--                  <span-->
                  <!--                    v-if="!disabled"-->
                  <!--                    class="el-upload-list__item-delete"-->
                  <!--                    @click="handleDownload(file)"-->
                  <!--                  >-->
                  <!--                    <i class="el-icon-download"></i>-->
                  <!--                  </span>-->
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item prop="code" v-if="captchaOnOff">
            <span slot="label" class="form-label">{{ $t("yzm") }}</span>
            <el-input
              v-model="form.code"
              auto-complete="off"
              :placeholder="$t('phYzm')"
            >
              <template #prefix>
                <svg-icon name="validCode" />
              </template>
            </el-input>
            <div class="login-code">
              <img :src="codeUrl" @click="getCode" class="login-code-img" />
            </div>
          </el-form-item>
          <el-form-item size="large">
            <el-button
              type="primary"
              style="width: 100%"
              @click="onSubmit"
              :loading="btnDisabled"
              >{{ $t("submitBtn") }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <el-dialog
      :title="$t('submitSuccess')"
      :close-on-click-modal="false"
      :show-close="false"
      :visible.sync="successDialogVisible"
      width="70%"
      center
    >
      <span style="text-align: center">{{ $t("demandSuccess") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showSuccess">{{
          $t("confirmTip")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { getCodeImg } from "@/api/login";

export default {
  name: "DemandView",
  data() {
    return {
      form: {
        companyName: "",
        contactPerson: "",
        phone: "",
        email: "",
        productName: "",
        desc: "",
        code: "",
        uuid: "",
        typo: 1,
      },
      rules: {
        companyName: [
          {
            required: true,
            message: this.$t("phCompanyName"),
            trigger: "blur",
          },
        ],
        contactPerson: [
          { required: true, message: this.$t("phName"), trigger: "blur" },
        ],
        phone: [
          {
            required: true,
            message: this.$t("phPhone"),
            pattern: /^(?!0)\d{1,20}$/,
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            type: "email",
            message: this.$t("phEmail"),
            trigger: "change",
          },
        ],
        productName: [
          { required: true, message: this.$t("phProduct"), trigger: "blur" },
        ],
        desc: [
          {
            required: true,
            message: this.$t("phProductDevReq"),
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: this.$t("phYzm"), trigger: "blur" }],
      },
      labelPosition: "top",
      upload: {
        // 是否显示弹出层（导入）
        open: false,
        // 弹出层标题（导入）
        title: "",
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的用户数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: {},
        // 上传的地址
        url: process.env.VUE_APP_BASE_API + "/bdaplat/engineering/importData",
      },
      // 验证码开关
      captchaOnOff: true,
      codeUrl: "",
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      successDialogVisible: false,
      btnDisabled: false,
    };
  },
  computed: {
    demandBg() {
      const lang = this.$i18n.locale;
      switch (lang) {
        case "zh":
          return require("@/assets/demand.png");
        case "en":
          return require("@/assets/demand_en.png");
        default:
          return require("@/assets/demand.png");
      }
    },
  },
  created() {
    this.getCode();
  },
  methods: {
    //判断文件类型, 图片预览
    updateIf(e) {
      if (e.fileName) {
        if (
          e.fileName.split(".")[1] === "png" ||
          e.fileName.split(".")[1] === "jpeg" ||
          e.fileName.split(".")[1] === "jpg"
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        if (
          e.name.split(".")[1] === "png" ||
          e.name.split(".")[1] === "jpeg" ||
          e.name.split(".")[1] === "jpg"
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    getCode() {
      getCodeImg().then((res) => {
        this.captchaOnOff =
          res.captchaOnOff === undefined ? true : res.captchaOnOff;
        if (this.captchaOnOff) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.form.uuid = res.uuid;
        }
      });
    },
    goBack() {
      this.$router.go(-1); // 后退到上一页
    },
    handleRemove(file) {
      // console.log(file);
      this.$refs.upload.handleRemove(file);
      let index = this.fileList.findIndex((item) => {
        if (item.file.uid === file.uid) {
          return true;
        }
      });
      // console.log("index", index);
      if (index >= 0) {
        this.fileList.splice(index, 1);
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleUploadFile(param) {
      // console.log("handleUploadFile: ", param);
      this.fileList.push(param);
    },
    // 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      console.log(event, file, fileList);
      this.upload.isUploading = true;
    },
    // 文件上传成功处理
    handleFileSuccess(response, file, fileList) {
      console.log(file, fileList);
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      this.$alert(
        "<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" +
          response.msg +
          "</div>",
        "导入结果",
        {
          dangerouslyUseHTMLString: true,
        }
      );
      this.getList();
    },
    async onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          const formData = new FormData();
          formData.append("code", this.form.code);
          formData.append("uuid", this.form.uuid);
          formData.append("companyName", this.form.companyName);
          formData.append("contactPerson", this.form.contactPerson);
          formData.append("phone", this.form.phone);
          formData.append("email", this.form.email);
          formData.append("productName", this.form.productName);
          formData.append("desc", this.form.desc);
          formData.append("typo", this.form.typo);
          // 将上传文件数组依次添加到参数paramsData中
          this.fileList.forEach((it, index) => {
            formData.append(`file${index}_${it.file.name}`, it.file);
          });
          const headers = {
            "Content-Type": "multipart/form-data",
          };
          const loading = this.$loading({
            lock: true,
            text: this.$t("submitData"),
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          try {
            this.btnDisabled = true;
            const response = await axios.post(
              process.env.VUE_APP_BASE_API + "/qualityplat/feedback/submit",
              formData,
              { headers }
            );
            console.log("response", JSON.stringify(response));
            if (response.status == 200 && response.data.code == 200) {
              // this.$emit("addAttachTypo0Callback", response.data.data)
              this.successDialogVisible = true;
              // this.$modal.msgSuccess("问题反馈成功, 请等待相关人员联系");
              // this.fileList = [];
              // this.$refs.upload.clearFiles();
              // this.goBack();
            } else if (response.status == 200 && response.data.code == 500) {
              this.$modal.msgError(response.data.msg);
            }
            this.btnDisabled = false;
          } catch (error) {
            this.btnDisabled = false;
            console.error("Error uploading file:", error);
          } finally {
            loading.close();
          }
        }
      });
    },
    showSuccess() {
      this.fileList = [];
      this.$refs.upload.clearFiles();
      this.goBack();
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback-form {
  //border: 1px solid red;
  // background: url("../../assets/demand.png") no-repeat;
  // background-size: 100% 100%;
  // background-attachment: fixed;
  // height: 100vh;
  // background: url("@/assets/demand.png") no-repeat center center / cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100vh;

  .container-fb {
    display: flex;
    flex: 1;
    .top-box {
      padding: 22px 0 0 15px;
      position: absolute;
      z-index: 1000; /* 确保div位于其他内容之上 */
      width: 100px;
      text-align: left;

      .navigator {
        font-size: 16px;
        font-weight: bold;
      }
    }

    .center-box {
      margin-top: 172px;
      padding: 0 24px;
      display: flex;
      flex: 1;
      border-radius: 9px 9px 0 0;
      background: white;

      .form-label {
        font-weight: bold;
        font-size: 16px;
      }

      .form-input {
        width: 100%;
      }

      .login-code {
        padding-top: 16px;
        width: 33%;
        //height: 38px;
        //float: right;

        img {
          //cursor: pointer;
          //vertical-align: middle;
        }
      }
    }
  }
}

::v-deep .el-input__inner {
  background-color: #f4f6fa;
  border: none;
  box-shadow: none;
}
</style>
