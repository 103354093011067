export default {
  queryTip: "查询",
  resetTip: "重置",
  exportTip: "导出",
  confirmTip: "确 定",
  cancelTip: "取 消",
  addBtn: "新增",
  editBtn: "修改",
  delBtn: "删除",
  back: "返回",
  companyName: "公司名称",
  phCompanyName: "请输入公司名称",
  name: "姓名",
  phName: "请输入姓名",
  phone: "联系人手机",
  phPhone: "请输入联系人手机",
  email: "联系人邮箱",
  phEmail: "请输入联系人邮箱",
  product: "使用产品",
  phProduct: "请输入使用产品",
  desc: "问题描述",
  phDesc: "您填写的信息越全, 问题越有可能有效解决哦~",
  uploadImg: "上传图片",
  yzm: "验证码",
  phYzm: "请输入验证码",
  submitBtn: "提 交",
  submitSuccess: "提交成功",
  feedbackSuccess: "问题反馈成功, 请等待相关人员联系",
  submitData: "数据提交中...",
  productDevReq: "产品开发需求",
  phProductDevReq: "请描述您想要的产品开发需求...",
  uploadAttach: "上传附件",
  demandSuccess: "产品开发需求提交成功, 请等待相关人员联系",

  home: {
    feedback: "质量问题反馈",
    feedbackSubTitle: "描述问题，获取帮助",
    techSupport: "技术支持引擎",
    techSupportSubTitle: "了解产品，技术相关内容",
    demand: "产品开发需求",
    demandSubTitle: "客户产品需求描述",
  },
  techSupport: {
    productNameDesc: "需要了解的产品或工艺",
    inputPlaceHolder: "请输入需要了解的产品名称或工艺名称",
    tip: "温馨🔔: 尽可能填写完整产品名称或工艺名称, 比如产品: E6CR-1200-386, 工艺：管道成型用玻璃纤维产品",
    btnManual: "产品查询",
    btnManualSubTitle: "对应产品性能与使用说明",
    btnTechnique: "工艺查询",
    btnTechniqueSubTitle: "对应产品使用工艺说明",
    warningTip: "未填写产品名称",
    noManual: "暂无该产品说明手册或不支持查询",
    noTechnique: "暂无该工艺说明或不支持查询",
    networkError: "请检查当前网络",
    prefix: "一共找到 ",
    manualSuccess: " 条相关产品说明手册",
    techniqueSuccess: " 条相关工艺说明手册",
  },
  dovView: {
    feedback: "Quality Issue",
    demand: "Development Requires",
  },
};
