<template>
  <div class="docview-container">
    <div class="header">
      <div class="top-box" @click="goBack">
        <i
          style="font-size: 16px; font-weight: bold"
          class="el-icon-arrow-left"
        ></i>
        <span class="navigator">{{ $t("back") }}</span>
      </div>

      <div class="btn-area">
        <div class="item" @click="gotoFeedback">
          <div class="img-container">
            <el-image class="img" :src="feedbackIconSmall"></el-image>
          </div>

          <span>{{ $t("dovView.feedback") }}</span>
        </div>
        <div class="item" @click="gotoDemand">
          <div class="img-container">
            <el-image class="img" :src="demandIconSmall"></el-image>
          </div>

          <span>{{ $t("dovView.demand") }}</span>
        </div>
      </div>
    </div>

    <div style="margin-top: 120px">
      <el-select v-model="b64Url">
        <el-option
          v-for="item in productOpts"
          :key="item.productName"
          :label="item.productName"
          :value="item.b64DocUrl"
        />
      </el-select>
    </div>

    <div class="content">
      <iframe
        ref="docViewIframe"
        sandbox="allow-scripts allow-same-origin"
        style="width: 100%; height: 100%"
        :src="b64Url"
        frameborder="0"
        @load="onIframeLoad"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
import eventBus from "@/utils/event-bus.js";
import { Base64 } from "js-base64";
export default {
  name: "DocView",
  data() {
    return {
      feedbackIconSmall: require("@/assets/feedback-icon-s.png"),
      demandIconSmall: require("@/assets/demand-icon-s.png"),
      b64Url: null,
      tag: null,
      productName: null,

      products: [],
      productOpts: [],
    };
  },
  mounted() {
    this.products = this.$route.query.products;
    this.productOpts = [];
    this.products.forEach((p) => {
      this.productOpts.push({
        productName: p.productName,
        b64DocUrl: `https://quality.jushi.com/fview/onlinePreview?url=${encodeURIComponent(
          Base64.encode(p.docUrl)
        )}`,
      });
    });
    this.tag = this.$route.query.tag;
    this.productName = this.$route.query.productName;

    this.b64Url = `https://quality.jushi.com/fview/onlinePreview?url=${encodeURIComponent(
      Base64.encode(this.products[0].docUrl)
    )}`;

    // this.b64Url = `https://quality.jushi.com/fview/onlinePreview?url=${encodeURIComponent(
    //   this.$route.query.b64Url
    // )}`;
    // this.tag = this.$route.query.tag;
    // this.productName = this.$route.query.productName;

    window.addEventListener("message", this.handleIframeMessage);
    setTimeout(() => {
      this.modifyIframeStyle();
    }, 100);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleIframeMessage);
  },
  methods: {
    onIframeLoad() {
      console.log("iframe loaded");
    },
    modifyIframeStyle() {
      const message = {
        type: "MODIFY_STYLE",
        selector: ".my-photo",
        styles: { padding: "0px", margin: "0px" },
      };
      this.$refs.docViewIframe.contentWindow.postMessage(
        message,
        "http://it.jushi.com"
      );
      console.log("2222");
    },
    handleIframeMessage(event) {
      // 验证消息来源
      if (event.origin !== "http://it.jushi.com") return;

      // 处理来自 iframe 的消息
      if (event.data.type === "MODIFY_STYLE") {
        console.log("iframe 样式已修改");
      }
    },
    goBack() {
      eventBus.$emit("backparam", this.productName);
      this.$router.go(-1);
    },
    gotoFeedback() {
      this.$router.push("/feedback");
    },
    gotoDemand() {
      this.$router.push("/demand");
    },
  },
};
</script>

<style lang="scss" scoped>
.docview-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .header {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 105px;
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);

    .top-box {
      padding: 22px 0 0 15px;
      position: absolute;
      z-index: 1000;
      width: 100%;
      text-align: left;

      .navigator {
        font-size: 16px;
        font-weight: bold;
      }
    }

    .btn-area {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 55px;

      .item {
        width: 46%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .img-container {
          width: 45px;
          height: 45px;
          padding: 5px;
        }

        .img {
          width: 40px;
          height: 40px;
          // padding: 5px;
        }

        span {
          margin-left: 5px;
        }
      }
    }
  }

  .content {
    margin-top: 10px;
    margin-left: -20px;
    width: calc(100vw + 20px);
    height: calc(100vh - 130px);
  }
}
</style>
