import Vue from "vue";
import VueMeta from "vue-meta";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import plugins from "./plugin";
import i18n from "./lang";

Vue.use(VueMeta);
Vue.use(ElementUI);

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
});

Vue.config.productionTip = false;
Vue.use(plugins);

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
