<template>
  <div class="card" @click="goto">
    <div class="left">
      <div class="img-container">
        <el-image :src="cardInfo.icon" class="icon-image"></el-image>
      </div>
      <div class="item">
        <div class="title">
          {{ cardInfo.title }}
        </div>
        <div class="desc">
          {{ cardInfo.desc }}
        </div>
      </div>
    </div>
    <div class="right-arrow">
      <el-image :src="arrow" width="13" height="24" class="arrow"></el-image>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardItem",
  props: {
    cardInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      arrow: require("@/assets/icon/right-arrow.png"),
    };
  },
  methods: {
    goto() {
      this.$router.push(this.cardInfo.path);
    },
  },
};
</script>

<style lang="scss" scoped>
.card:active {
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.3);
}
.card {
  margin-top: 20px;
  width: 92%;
  height: 108px;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .img-container {
      width: 55px;
      height: 55px;

      .icon-image {
        width: 55px;
        height: 55px;
      }
    }

    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin-left: 15px;
      height: 54px;

      .title {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 16px;
        color: #000000;
        letter-spacing: 1px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      .desc {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #b2b2b2;
        letter-spacing: 1px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }

  .right-arrow {
    width: 15px;
    height: 25px;
  }
}
</style>
