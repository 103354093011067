<template>
  <div
    class="support-form"
    :style="{ backgroundImage: `url(${techSupportBg})` }"
  >
    <div class="container">
      <div class="top-box" @click="goBack">
        <i
          style="font-size: 16px; font-weight: bold"
          class="el-icon-arrow-left"
        ></i>
        <span class="navigator">{{ $t("back") }}</span>
      </div>
      <div class="center-box">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          style="width: 100%; margin-top: 33px"
        >
          <el-form-item prop="productName">
            <span slot="label" class="form-label">{{
              $t("techSupport.productNameDesc")
            }}</span>
            <el-input
              v-model="productName"
              :placeholder="$t('techSupport.inputPlaceHolder')"
            />
          </el-form-item>
          <el-form-item prop="productName">
            <span slot="label" class="form-label-small">
              {{ $t("techSupport.tip") }}
            </span>
          </el-form-item>
          <el-form-item size="large">
            <div class="card-list">
              <div class="card" @click="handleProductManaul">
                <span class="title">{{ $t("techSupport.btnManual") }}</span>
                <span class="desc">{{
                  $t("techSupport.btnManualSubTitle")
                }}</span>
              </div>
              <div class="card" @click="handleProductTechnique">
                <span class="title">{{ $t("techSupport.btnTechnique") }}</span>
                <span class="desc">{{
                  $t("techSupport.btnTechniqueSubTitle")
                }}</span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
// import { Base64 } from "js-base64";
import eventBus from "@/utils/event-bus.js";
import axios from "axios";
export default {
  name: "TechSupportView",
  metaInfo: {
    title: "技术支持引擎",
    meta: [{ name: "技术支持引擎", content: "技术支持引擎内容查询" }],
  },
  computed: {
    techSupportBg() {
      const lang = this.$i18n.locale;
      switch (lang) {
        case "zh":
          return require("@/assets/support.png");
        case "en":
          return require("@/assets/support_en.png");
        default:
          return require("@/assets/support.png");
      }
    },
  },
  data() {
    return {
      productName: null,
      form: {
        productName: null,
      },
      rules: {},
    };
  },
  created() {
    // this.eventListener();
  },
  mounted() {
    this.addListener();
  },
  methods: {
    addListener() {
      eventBus.$on("backparam", (data) => {
        this.productName = data;
        this.$nextTick(() => {
          console.log("更新后的productName:", this.productName);
        });
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    gotoFeedback() {
      this.$router.push("/feedback");
    },
    gotoDemand() {
      this.$router.push("/demand");
    },
    async handleProductManaul() {
      this.doGetInfo(0);
    },
    handleProductTechnique() {
      this.doGetInfo(1);
    },
    async doGetInfo(tag) {
      if (this.productName == null || this.productName.trim() == "") {
        this.$modal.msgWarning(this.$t("techSupport.warningTip"));
        return;
      }
      const headers = {
        "Content-Type": "application/json",
      };
      const postData = {
        productName: this.productName,
      };
      let url = "";
      if (tag == 0) {
        url = process.env.VUE_APP_BASE_API + "/qualityplat/product/manualDoc";
      } else {
        url =
          process.env.VUE_APP_BASE_API + "/qualityplat/product/techniqueDoc";
      }
      let response = await axios.post(url, postData, { headers });

      if (response.status == 200) {
        if (response.data.code == 200) {
          const d = response.data.data;
          if (tag == 0) {
            this.$modal.msgSuccess(
              this.$t("techSupport.prefix") +
                d.length +
                this.$t("techSupport.manualSuccess")
            );
          } else if (tag == 1) {
            this.$modal.msgSuccess(
              this.$t("techSupport.prefix") +
                d.length +
                this.$t("techSupport.techniqueSuccess")
            );
          }
          console.log("ddd: ", d);
          this.$router.push({
            path: "/docView",
            query: {
              // b64Url: b64Url,
              products: d,
              t: tag == 0 ? "manual" : "technique",
              productName: this.productName,
            },
          });
          // if (!d.productDoc.docManual && tag == 0) {
          //   this.$modal.msgWarning(this.$t("techSupport.noManual"));
          // } else if (!d.productDoc.docTechnique && tag == 1) {
          //   this.$modal.msgWarning(this.$t("techSupport.noTechnique"));
          // } else {
          //   let b64Url = "";
          //   if (tag == 0) {
          //     b64Url = Base64.encode(d.productDoc.productManualData.signedUrl);
          //   } else {
          //     b64Url = Base64.encode(
          //       d.productDoc.productTechniqueData.signedUrl
          //     );
          //   }
          //   this.$router.push({
          //     path: "/docView",
          //     query: {
          //       b64Url: b64Url,
          //       t: tag == 0 ? "manual" : "technique",
          //       productName: this.productName,
          //     },
          //   });
          // }
        } else {
          this.$modal.msgError(response.data.msg);
        }
      } else {
        this.$modal.msgError(this.$t("techSupport.networkError"));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.support-form {
  // background: url("@/assets/support.png") no-repeat;
  // background-size: 100% 100%;
  // background-attachment: fixed;
  // background: url("@/assets/support.png") no-repeat center center / cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100vh;

  .container {
    display: flex;
    flex: 1;

    .top-box {
      padding: 22px 0 0 15px;
      position: absolute;
      z-index: 1000; /* 确保div位于其他内容之上 */
      width: 100px;
      text-align: left;

      .navigator {
        font-size: 16px;
        font-weight: bold;
      }
    }

    .center-box {
      margin-top: 172px;
      height: calc(100vh - 172px);
      padding: 0 24px;
      display: flex;
      flex: 1;
      border-radius: 9px 9px 0 0;
      background: white;
      position: relative;

      .form-label {
        font-weight: bold;
        font-size: 16px;
      }

      .form-label-small {
        font-size: 12px;
        color: cornflowerblue;
        font-weight: 600;
      }

      .form-input {
        width: 100%;
      }

      .card-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .card:active {
          box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.3);
        }

        .card {
          background: linear-gradient(
            180deg,
            rgba(15, 109, 254, 0.19) 0%,
            rgba(43, 87, 252, 0.06) 100%
          );
          border-radius: 12px 12px 12px 12px;
          padding: 1cap;

          border: 1px dashed gainsboro;
          width: 48%;
          height: 81px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .title {
            margin-top: 12px;
            font-weight: bold;
            font-size: 17px;
            color: #000000;
            line-height: 0px;
            text-align: left;
            font-style: normal;
          }

          .desc {
            margin-top: 12px;
            font-weight: 400;
            font-size: 10px;
            color: #000000;
            line-height: 13px;
            text-align: left;
            font-style: normal;
          }
        }
      }
    }
  }
}
</style>
