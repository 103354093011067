<template>
  <el-dropdown
    trigger="click"
    class="international"
    @command="handleSetLanguage"
  >
    <span>语言/Language</span>
    <span class="el-dropdown-link">
      <template v-if="language == 'zh'"> 中文 </template>
      <template v-if="language == 'en'"> English </template>
      <i class="el-icon-arrow-down el-icon--right"></i>
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item :disabled="language === 'zh'" command="zh">
        中文
      </el-dropdown-item>
      <el-dropdown-item :disabled="language === 'en'" command="en">
        English
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: "LangSelect",
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
  created() {
    console.log("lang:", this.$store.getters.language);
  },
  methods: {
    handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch("setLanguage", lang);
      // this.$message({
      //   message: "设置语言成功/Change language success",
      //   type: "success",
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.international {
  z-index: 1001;
}
</style>
